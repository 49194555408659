import React from "react"
import "twin.macro"

// Components
import Layout from "@components/layout"

import Container from "@components/container"
import Section from "@components/section"
import Seo from "@components/seo"
import SubscribeFormIH from "@components/subscribeFormIH"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

// Assets
import logo from "@images/logos/xywav-logo.svg"
const StayConnectedIH = ({ location }) => {
  const RECAPTCHA_3_KEY = '6LdL8csqAAAAAC7PyiXqW3d5cCCshA4nlwgBHXmA'

  return (
    <Layout mainBgColour="lilac" location={location}>
      <Seo
        title="Sign Up to Receive Info About Idiopathic Hypersomnia | XYWAV® IH"
        description="Register to receive information regarding XYWAV® (calcium, magnesium, potassium, and sodium oxybates) and XYWAV Mentor Program. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <Section noHero>
        <Container>
          <img
            src={logo}
            width="630px"
            alt=""
            tw="hidden xl:(block absolute -top-[30px] right-[-285px])"
          />
          <div tw="xl:(max-w-[820px])">
            <p tw="font-black text-xs xl:(text-2xl)">Register Today</p>
            <h1 tw="text-studio font-cocon font-semibold text-[32px] leading-[115%] mt-2.5 z-10 xl:(text-[44px])">
              Stay connected throughout your Idiopathic Hypersomnia (IH) journey
            </h1>
            <p tw="mt-6 xl:(text-4xl mt-8)">
              Complete this form to receive all the support that XYWAV has to
              offer, get&nbsp;alerts about educational programs, learn about the
              XYWAV Mentor&nbsp;Program, and more!
            </p>
          </div>
        </Container>
        <div tw="pt-7 xl:(py-10)">
          <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_3_KEY}>
            <SubscribeFormIH />
          </GoogleReCaptchaProvider>
        </div>
      </Section>
    </Layout>
  )
}

export default StayConnectedIH
